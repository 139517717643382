<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>Medical History</div>
</template>
<script>
export default {
  name: "medical-history",
};
</script>
<style lang="scss" scoped></style>
