import moment from "moment";

/* eslint-disable prettier/prettier */
export const utilMixin = {
  methods: {
    getColor(string: string) {
      const char = string.split("")[0].toUpperCase();

      switch (char) {
        case "A":
          return "#CE93D8";
        case "B":
          return "#F48FB1";
        case "C":
          return "#B39DDB";
        case "D":
          return "#9FA8DA";
        case "E":
          return "#90CAF9";
        case "F":
          return "#81D4FA";
        case "G":
          return "#80DEEA";
        case "H":
          return "#80CBC4";
        case "I":
          return "#A5D6A7";
        case "J":
          return "#C5E1A5";
        case "K":
          return "#E6EE9C";
        case "L":
          return "#FFF59D";
        case "M":
          return "#BEA044";
        case "N":
          return "#FFCC80";
        case "O":
          return "#FFAB91";
        case "P":
          return "#916F63";
        case "Q":
          return "#B0BEC5";
        case "R":
          return "#E57373";
        case "S":
          return "#F06292";
        case "T":
          return "#BA68C8";
        case "U":
          return "#9575CD";
        case "V":
          return "#7986CB";
        case "W":
          return "#64B5F6";
        case "X":
          return "#4FC3F7";
        case "Y":
          return "#4DD0E1";
        case "Z":
          return "#4DB6AC";
        default:
          return "#EF9A9A";
      }
    },
    getRandomColor(id: number) {
      const colors = [
        "#CE93D8",
        "#F48FB1",
        "#B39DDB",
        "#9FA8DA",
        "#90CAF9",
        "#81D4FA",
        "#80DEEA",
        "#80CBC4",
        "#A5D6A7",
        "#C5E1A5",
        "#E6EE9C",
        "#FFF59D",
        "#BEA044",
        "#FFCC80",
        "#FFAB91",
        "#916F63",
        "#B0BEC5",
        "#E57373",
        "#F06292",
        "#BA68C8",
        "#9575CD",
        "#7986CB",
        "#64B5F6",
        "#4FC3F7",
        "#4DD0E1",
        "#4DB6AC",
        "#EF9A9A",
      ];
      const index = Math.floor(Math.random() * colors.length);

      return colors[index];
    },
    getColorsNumber(id: number) {
      switch (id) {
        case 1:
          return "indigo darken-1";
        case 0:
          return "cyan darken-1";
        case 8:
          return "orange darken-1";
        case 2:
          return "green darken-3";
        case 3:
          return "blue darken-1";
        case 4:
          return "gray darken-1";
        case 5:
          return "red darken-1";
        case 6:
          return "pink darken-1";
        case 7:
          return "yellow darken-3";
        case 17:
          return "red darken-3";
        case 9:
          return "blue darken-3";
        case 10:
          return "green darken-1";
        case 11:
          return "pink darken-3";
        case 12:
          return "orange darken-3";
        case 13:
          return "indigo darken-3";
        case 14:
          return "cyan darken-3";
        case 15:
          return "red darken-2";
        case 16:
          return "blue darken-2";
        case 18:
          return "yellow darken-2";
        case 19:
          return "green darken-2";

        default:
          return "deep-purple darken-1";
      }
    },
    getDate(date: string) {
      const d = moment(date);
      return d.format("M/DD/yyyy");
    },
    getColorStatus(status: string) {
      switch (status) {
        case "NEW":
          return "#ab47bc";
        case "EVALUATION":
          return "#ec407a";
        case "NO_INTERESTED":
          return "#e91e63";
        case "PENDING":
          return "#f44336";
        case "PAYMENT_IN_PROCCESS":
          return "#26a69a";

        default:
          return "#43a047";
      }
    },

    cleanNull(obj: any) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },

    prettyDateShow(date: string) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
  },
};
